<template>
  <c-step
    :stepperGrpCd="stepperGrpCd"
    :currentStepCd="popupParam.mocStepCd"
    :param="param"
    :stepDisabledItems="stepDisabledItems"
    v-model="mocStepCd"
    @stepBystep="stepBystep"
    @keySetting="keySetting"
    @changePreStartup="changePreStartup"
    @closePopup="closePopup">
  </c-step>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'moc-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopMocId: '',
        mocTypeCd: '',
        mocStepCd: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      mocStepCd: '',
      detailUrl: '',
      param: {
        sopMocId: '',
        mocTypeCd: '',
        mocStepCd: '',
        committeeHold: {
          sopMocId: '',  // MOC 일련번호
          changeApprovalFlag: '',  // 변경 승인 여부
          changeApprovalReason: '',  // 변경 승인 사유
          changeApprovalDate: '',  // 승인일
        },
        changePreStartup: '',
      },
      stepDisabledItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'popupParam.mocStepCd'() {
      this.setStep();
    },
  },
  computed: {
    stepperGrpCd() {
      return this.popupParam.mocTypeCd === 'MT00000001' ? 'MOC_NORMAL_STEP_CD' :
        this.popupParam.mocTypeCd === 'MT00000005' ? 'MOC_TEMP_STEP_CD' : 'MOC_EMERGENCY_STEP_CD'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.moc.change.approvalFlag.get.url
      // url setting
      // code setting
      // list setting
      this.setStep();
      this.getDetail();
    },
    getDetail() {
      this.$_.extend(this.param, this.popupParam)
      if (this.popupParam.sopMocId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopMocId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          // 변경관리 승인여부 판단
          this.$_.extend(this.param.committeeHold, _result.data);
          this.stepDisabledItems = [];
          if (this.param.committeeHold.changeApprovalFlag !== 'Y') {
            if (this.popupParam.mocTypeCd === 'MT00000001') {
              this.stepDisabledItems = ['MS00000010', 'MS00000015']
            } else {
              this.stepDisabledItems = ['MT00000010', 'MT00000015']
            }
          }
        },);
      }
    },
    setStep() {
      /**
       * ## 정상 변경
       * MS00000010 MS00000015 단계는 같은 단계로 취급 
       * MS00000010으로 데이터가 저장되지 않고 MS00000015으로 저장
       * MS00000015인 경우 팝업에서는 '평가실행'으로 표시되도록 MS00000010으로 셋팅
       * 
       * ## 임시 변경
       * MT00000010 MT00000015 MT00000020 단계는 같은 단계로 취급 
       * MT00000010으로 데이터가 저장되지 않고 MT00000020으로 저장
       * MT00000020인 경우 팝업에서는 '평가실행'으로 표시되도록 MT00000010으로 셋팅
       * 
       * ## 비상 변경
       * ME00000008 ME00000010 단계는 같은 단계로 취급 
       * ME00000008으로 데이터가 저장되지 않고 ME00000010으로 저장
       * ME00000010인 경우 팝업에서는 '실시'으로 표시되도록 ME00000008으로 셋팅
       *  */ 
      if (this.popupParam.mocStepCd === 'MS00000015') {
        this.mocStepCd = 'MS00000010'
      } else if (this.popupParam.mocStepCd === 'MT00000020') {
        this.mocStepCd = 'MT00000010'
      } else if (this.popupParam.mocStepCd === 'ME00000010') {
        this.mocStepCd = 'ME00000008'
      } else {
        // 신규등록인 경우 진행상태 셋팅
        let mocStepCd = this.popupParam.mocTypeCd === 'MT00000001' ? 'MS00000000' : this.popupParam.mocTypeCd === 'MT00000005' ? 'MT00000000' : 'ME00000001'

        // 보고자 하는 상태 표시
        this.mocStepCd = this.popupParam.mocStepCd ? this.$_.clone(this.popupParam.mocStepCd) : mocStepCd;
      }
    },
    /* eslint-disable no-unused-vars */
    stepBystep(stepCd) {
      this.popupParam.mocStepCd = stepCd
      this.getDetail();
    },
    keySetting(key) {
      this.popupParam.sopMocId = key
    },
    changePreStartup() {
      this.param.changePreStartup = uid()
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>
